import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Layout from '../components/Layout';
import { HTMLContent } from '../components/Content';
import BlogPostTemplate from '../components/blog/BlogPostTemplate';

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { t } = useTranslation();

  return (
    <Layout withMeta>
      <BlogPostTemplate
        content={post.html}
        helmet={
          <Helmet titleTemplate={`%s | ${t('blog.blog')}`}>
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta name={`og:title`} content={post.frontmatter.title} />
            <meta
              name={`og:description`}
              content={post.frontmatter.description}
            />
            <meta name={`twitter:card`} content={`summary`} />
            <meta name={`twitter:creator`} content={`Rybalka Denis`} />
            <meta name={`twitter:title`} content={post.frontmatter.title} />
            <meta
              name={`twitter:description`}
              content={post.frontmatter.description}
            />
            {post.frontmatter.indexed ? null : (
              <meta name={`robots`} content={`noindex, nofollow`} />
            )}
          </Helmet>
        }
        contentComponent={HTMLContent}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`;
